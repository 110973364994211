#team
  .box
    margin-top 35px

  ul
    li
      margin-left -25px

  .other-employees
    img
      margin-bottom 10px