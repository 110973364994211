body
  font-family FuturaBT-Book
  font-size 19px
  line-height 1.6em
  font-weight 400
  -webkit-font-smoothing antialiased

strong
  font-weight bold

main
  margin-top 50px

ul.dash
  list-style none

  li
    margin-bottom 10px
    &:before
      content '-'
      position absolute
      margin-left -15px

.red, a
  color $colorRed

a:hover
  text-decoration underline
  color $colorRed

#map_canvas
  width 100%
  height 400px
  display block
			