footer
  margin-top 15px
  border-top 2px solid $colorRed
  padding-top 10px
  width 100%
  font-size 16px

  ul
    padding 0
    margin 0
    li
      white-space nowrap
      color $colorRed
      float left
      margin-right 10px
      padding-right 20px

      &:first-child
        list-style none

  a
    color $colorRed

    &:hover
      text-decoration underline
      color $colorRed