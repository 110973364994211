table
  width 100%
  margin-top 25px
  margin-bottom 25px

  tr
    td
      @media screen and (min-width: 320px)
        display block
        padding-bottom 5px

      @media screen and (min-width: 768px)
        padding-bottom 20px
        display table-cell

      &:first-child
        font-weight bold
        width 125px
        vertical-align: top;

        @media screen and (min-width: 320px)
          text-align left

        @media screen and (min-width: 768px)
          text-align right

      &:last-child
        padding-left 25px