#notfall
  background-color $colorRed
  color #fff
  padding 5px 0 50px 0

  .box
    margin-bottom 15px

  a
    color #fff
    font-weight bold