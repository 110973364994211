@font-face
  font-family 'FuturaBT-Book'
  src url('../fonts/30DF1D_0_0.eot')
  src url('../fonts/30DF1D_0_0.eot?#iefix') format('embedded-opentype')

,
url('../fonts/30DF1D_0_0.woff2') format('woff2')
,
url('../fonts/30DF1D_0_0.woff') format('woff')
,
url('../fonts/30DF1D_0_0.ttf') format('truetype')

@font-face
  font-family 'FuturaBT-Medium'
  src url('../fonts/30DF1D_1_0.eot')
  src url('../fonts/30DF1D_1_0.eot?#iefix') format('embedded-opentype')

,
url('../fonts/30DF1D_1_0.woff2') format('woff2')
,
url('../fonts/30DF1D_1_0.woff') format('woff')
,
url('../fonts/30DF1D_1_0.ttf') format('truetype')