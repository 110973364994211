.navbar-default
  font-size 17px
  margin-bottom 20px
  background-color #fff
  border 0
  color #000
  position relative

  aside
    position absolute
    left 60px
    top 20px

    @media(max-width: 991px)
      left 111px
      top 60px

    @media(min-width: 1200px)
      left 115px

    a
      color #000
      font-size 13px
      display inline-block

  .navbar-collapse, .navbar-form
    border-color transparent

  .navbar-nav
    > li
      > a
        color #000
        &:hover, &:focus
          color $colorRed

    > .active
      a, a:hover, a:focus
        color $colorRed
        background-color #fff

  .navbar-left
    margin-top 65px

.nav
  > li
    > a
      padding 10px

.navbar
  .logo
    img
      display inline-block

.navbar-small-container
  display flex
  justify-content center
