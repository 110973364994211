#philosophie
  strong
    margin-bottom 15px
    display block

  p
    margin-bottom 15px

  img:first-child
    margin-bottom 35px

@media only screen and (min-width: 320px) {

}

@media only screen and (min-width: 768px) {

}
